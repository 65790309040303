


// variables and mixins
@import "elite-feats-cms/variables";
@import "elite-feats-cms/mixins";
@import "elite-feats-cms/shadows";

// plugin css
@import "elite-feats-cms/plugins/plugin-nouislider";
@import "elite-feats-cms/plugins/plugin-perfect-scrollbar";
@import "elite-feats-cms/plugins/plugin-react-datetime";
@import "elite-feats-cms/plugins/plugin-react-bootstrap-sweetalert";
@import "elite-feats-cms/plugins/plugin-react-chartist";
@import "elite-feats-cms/plugins/plugin-react-big-calendar";
@import "elite-feats-cms/plugins/plugin-react-jvectormap";
@import "elite-feats-cms/plugins/plugin-react-table";
@import "elite-feats-cms/plugins/plugin-react-tagsinput";

// Core CSS
@import "elite-feats-cms/misc";
@import "elite-feats-cms/fileupload";
@import "elite-feats-cms/fixed-plugin";
